import React from 'react';
import FacebookPage from '@business/scheduler/FacebookPage';
import FacebookPost from '@business/scheduler/FacebookPost';
import SchedulePhase from '@business/scheduler/SchedulePhase';
import { EntityManager } from '@xFrame4/business/base/BusinessEntity';
import { t } from '@xFrame4/common/Utils';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import EntityAddEditForm from '@xFrame4/components/admin/EntityAddEditForm';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import { useLoad } from '@xFrame4/components/Hooks';
import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '@xFrame4/common/Functions';
import { FacebookPostTypeOfPost } from '@business/scheduler/generated/FacebookPost.generated';

interface ScheduleABunchOfPostsFormProps
{
    onClose: () => void;
}

const ScheduleABunchOfPostsForm: FunctionComponent<ScheduleABunchOfPostsFormProps> = (props) =>
{
    const [typeOfPost, setTypeOfPost] = useState<FacebookPostTypeOfPost>();
    const [startDate, setStartDate] = useState<string>(formatDate(Date.now()));
    const [facebookPages, setFacebookPages] = useState<FacebookPage[]>([]);
    const [selectedFacebookPages, setSelectedFacebookPages] = useState<FacebookPage[]>([]);
    const [schedulePhases, setSchedulePhases] = useState<SchedulePhase[]>([]);
    const [selectedSchedulePhases, setSelectedSchedulePhases] = useState<SchedulePhase[]>([]);
    const [links, setLinks] = useState<string>();
    const [photoFiles, setPhotoFiles] = useState<FileList>();
    const [getMessagesFromFileNames, setGetMessagesFromFileNames] = useState<boolean>(false);

    useLoad(async () =>
    {
        // Load Facebook pages
        let pagesResult = await FacebookPage.manager.load({ isActive: true });
        if (pagesResult != null) 
        {
            let pages = pagesResult.entities as FacebookPage[];
            setFacebookPages(pages);
        }

        // Load phases
        let phasesResult = await SchedulePhase.manager.load();
        if (phasesResult != null)
        {
            let phases = phasesResult.entities as SchedulePhase[];
            setSchedulePhases(phases);
        }
    });

    /** Validate form */
    const onValidate = () =>
    {
        if (selectedFacebookPages.length == 0 || selectedSchedulePhases.length == 0)
        {
            return false;
        }
        else
        {
            return true;
        }
    };

    /** Save */
    const onSave = async () =>
    {
        // Split links
        let splittedLinks;
        if (links)
        {
            splittedLinks = links?.split('\n');
        }

        // Schedule
        return await FacebookPage.scheduleABunchOfPosts(startDate, selectedFacebookPages, selectedSchedulePhases, splittedLinks, photoFiles, getMessagesFromFileNames);
    };

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="typeOfPost"
                        label="Type"
                        isRequired={true}
                        dataSource={enumToDataSource(FacebookPostTypeOfPost)}
                        value={typeOfPost}
                        onValueChanged={(field, value) => setTypeOfPost(value['value'])}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Date}
                        field="startDate"
                        label="Start date"
                        isRequired={true}
                        value={startDate}
                        onValueChanged={(field, value) => setStartDate(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.List}
                        field="pages"
                        label="Pages"
                        dataSource={facebookPages}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="nameDetailed"
                        value={selectedFacebookPages}
                        onValueChanged={(field, value) => setSelectedFacebookPages(value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.List}
                        field="phases"
                        label="Phases"
                        dataSource={schedulePhases}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="sequenceNo"
                        value={selectedSchedulePhases}
                        onValueChanged={(field, value) => setSelectedSchedulePhases(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="links"
                        label="Links"
                        value={links}
                        isRequired={typeOfPost == FacebookPostTypeOfPost.LINK}
                        isVisible={typeOfPost == FacebookPostTypeOfPost.LINK}
                        onValueChanged={(field, value) => setLinks(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="photos"
                        label="Photos"
                        allowMultipleFileUpload={true}
                        isRequired={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                        isVisible={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                        value={photoFiles}
                        onValueChanged={(field, value) => setPhotoFiles(value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="getMessagesFromFileNames"
                        label="Get messages from file names"
                        isVisible={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                        value={getMessagesFromFileNames}
                        onValueChanged={(field, value) => setGetMessagesFromFileNames(value)}
                    />
                </Col>
            </Row>
        </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm<FacebookPost>
                entity={new FacebookPost()}
                mode={AddEditFormMode.Add}
                titleAdd={'Schedule a bunch of posts'}
                onValidate={() => onValidate()}
                onSave={() => onSave()}
                onClose={() => props.onClose()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default ScheduleABunchOfPostsForm;