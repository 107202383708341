import BusinessEntity, { EntityManager, EntityManagerParameters } from '@xFrame4/business/base/BusinessEntity';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { ManyToManyCollection } from '@xFrame4/business/base/ManyToManyCollection';
import { BusinessEntityWithTranslation, BusinessEntityTranslation, EntityWithTranslationManager, EntityTranslationManager, EntityWithTranslationManagerParameters } from '@xFrame4/business/base/BusinessEntityWithTranslation';



export class QuoteGeneratedEntityManager<B extends QuoteGenerated> extends EntityManager<B>
{
    constructor(parameters: EntityManagerParameters<B>)
    {
        super({
            name: parameters.name ?? 'Quote',
            createEntity: parameters.createEntity,
            fields: [
				{ name: 'dateAdded', type: BusinessEntityFieldType.DateTime, isInput: false },
				{ name: 'language', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
				{ name: 'text', type: BusinessEntityFieldType.Text, isRequiredInput: true },
                ... parameters.fields ?? [],
            ],
            graphQlQueryAlias: parameters.graphQlQueryAlias ?? 'quotes',
        });
    }

}
export class QuoteGenerated extends BusinessEntity
{
	dateAdded!: string;
	language!: string;
	text!: string;

}

export default QuoteGenerated;