// FieldEditor components can be used for forms (or anywhere you need to edit fields) in your React app. 
// Here are some examples:
// 
// <FieldEditor
//     type={FieldEditorType.Text}
//     field="name"
//     label={t('NAME')}
//     isRequired={true}
//     value={entity?.name}
//     onValueChanged={(field, value) => updateEntityField(field, value)}
// />
// 
// <FieldEditor
//     type={FieldEditorType.Select}
//     field="region"
//     label={t('REGION')}
//     isRequired={true}
//     dataSource={regions}
//     dataSourceValueMember="id"
//     dataSourceDisplayMember="name"
//     value={entity?.region}
//     onValueChanged={(field, value) => updateEntityField(field, value)}
// />
// 
// <FieldEditor
//     type={FieldEditorType.Select}
//     field="specialAttributeType"
//     label={t('ATTRIBUTE_TYPE_OF_SPECIAL_ATTRIBUTE')}
//     dataSource={enumToDataSource(AttributeSpecialAttributeType, t)}
//     value={entity?.specialAttributeType}
//     onValueChanged={(field, value) => updateEntityField(field, value?.value)} // notice the value?.value. This is because of the enumToDataSource function
// />
// 
// <FieldEditor
//     type={FieldEditorType.File}
//     field="logoImageFile"
//     label={t('LOGO_IMAGE')}
//     onValueChanged={(field, value) => updateEntityField(field, value)}
// />
// 
// Use the useUpdateEntityFields Hook when editing fields of an entity. Eg:
// const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Address>(props.entity ?? new Address());
// 
// Types of FieldEditor:
// export enum FieldEditorType
// {
//     Text = 'text',
//     Email = 'email',
//     Password = 'password',
//     Number = 'number',
//     /** Accepts a list of objects. Single selection. */
//     Select = 'select',
//     /** Accepts a list of objects. Multiple selection. */
//     List = 'list',
//     Date = 'date',
//     Textarea = 'textarea',
//     RichTextEditor = 'rich-text-editor',
//     Checkbox = 'checkbox',
//     File = 'file',
// }
// 
// Props of FieldEditor:
// export interface FieldEditorProps
// {
//     /** The type of the field editor. */
//     type: FieldEditorType;
//     /** The name of the field. */
//     field: string;
//     /** The label for the editor. */
//     label?: string;
//     /** The position of the label. */
//     labelPosition?: FieldEditorLabelPosition;
//     /** Is this field required? */
//     isRequired?: boolean;
//     /** Is this field visible? */
//     isVisible?: boolean;
//     /** Is the editing enabled? */
//     isEnabled?: boolean;
//     /** The placeholder for the field value. */
//     placeholder?: string;
//     /** CSS class for the control. */
//     className?: string;
//     /** The datasource for Select, List or listlike editors. */
//     dataSource?: {[index: string]: any}[];
//     /** The datasource object's property that is human readable. */
//     dataSourceDisplayMember?: string;
//     /** The datasource object's property that is used as the real value for the field. */
//     dataSourceValueMember?: string;
//     /** Allow multiple file upload for an File editor control (needs a FileList object). */
//     allowMultipleFileUpload?: boolean;
//     /** The maximum length of the input text. */
//     maxLength?: number;
//     /** The minimum value of a number, range, date, datetime-local, month, time and week field. */
//     min?: string | number;
//     /** The maximum value of a number, range, date, datetime-local, month, time and week field. */
//     max?: string | number;
//     /** The number of rows in a text area. */
//     textareaRows?: number;
//     /** Insert an element before the input element. */
//     beforeInput?: JSX.Element
//     /** Insert an element after the input element. */
//     afterInput?: JSX.Element
//     /** The invalid feedback message. */
//     invalidFeedbackMessage?: string;
//     /** The reference to the file input element. Use this with useRef() to clear the file input value. (fileInputRef.current!.value = '') */
//     fileInputRef?: any;
//     /** The value of the field. */
//     value?: any;
//     /** Fired when the field value changes in the editor. */
//     onValueChanged?: (field: string, value: any) => void;
//     /** Fired when a key is pressed down */
//     onKeyDown?: (key: string) => void;
// }
// 
// FieldEditor.defaultProps = 
// {
//     labelPosition: FieldEditorLabelPosition.Vertical,
//     dataSourceValueMember: 'value',
//     dataSourceDisplayMember: 'display',
//     isRequired: false,
//     isVisible: true,
//     isEnabled: true,
//     allowMultipleFileUpload: false,
//     className: ''
// }
import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import FieldEditor, { enumToDataSource, FieldEditorType, stringArrayToDataSource } from '@xFrame4/components/common/FieldEditor';
import Quote from '@business/quotes/Quote';

interface QuoteAddEditFormProps extends EntityAddEditFormProps<Quote>
{

}

const QuoteAddEditForm: FunctionComponent<QuoteAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<Quote>(props.entity ?? new Quote());

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={1}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="language"
                        label={t('LANGUAGE')}
                        isRequired={true}
                        dataSource={stringArrayToDataSource(Quote.quoteLanguages)}
                        value={entity?.language}
                        onValueChanged={(field, value) => updateEntityField(field, value.value)}
                    />
                </Col>
                <Col lg={11}>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="text"
                        label={t('TEXT')}
                        isRequired={true}
                        value={entity?.text}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>
    ;

    /** Render */
    return (
        <>
            <EntityAddEditForm<Quote>
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t('QUOTE').toLowerCase()}
                titleEdit={t('EDIT') + ': ' + entity?.text}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default QuoteAddEditForm;