// How to work with BusinessEntity objects in TypeScript.
// 
// BusinessEntities are the objects that are used to represent the data in the database. They connect to the database via GraphQL API.
// 
// 1. Load all the entities: use the manager's load() method, check the result if it's null or not, then use the 'entities' property.
// Eg:
// let result = await BlogPost.manager.load();
// if (result)
// {
//     let blogPosts = result.entities;
// }
// 
// 2. Load the entities using a filter. Eg:
// BlogPost.manager.load({ author: 'Joe' });
// BlogPost.manager.load({ lastName_Icontains: 'Smith', firstName_Icontains: 'John' });
// Product.manager.load({ price_Gte: 100, price_Lte: 200 });
// 
// 3. Apply paging to the load method. Eg:
// blogPosts = BlogPost.manager.load({}, { first: 10, after: 'YXJyYXljb25uZWN0aW9uOjA=' }); // load 10 entities starting from the after cursor
// blogPosts = BlogPost.manager.load({}, { last: 10, before: 'YXJyYXljb25uZWN0aW9uOjA=' }); // load 10 entities before the before cursor
// 
// 4. Get single entity. Eg:
// blogPost = await BlogPost.manager.get({ id: 1 }); // get the entity with id 1
// blogPost = await BlogPost.manager.get({ slug: 'my-blog-post' }); // get the entity with slug 'my-blog-post'
// blogPost = await BlogPost.manager.getById(1); // get the entity with id 1
// 
// 5. Create a new entity: use the constructor of the entity class. Eg: let blogPost = new BlogPost();
// 6. Save the entity: use the save() method of the entity. Eg: await blogPost.save();
// 7. Delete the entity: use the delete() method of the entity. Eg: await blogPost.delete();
// 8. Load the related entities in a ManyToMany relationship: use the load method of ManyToManyCollection. Eg: await blogPost.tags.load();
// 9. Add one or more related entities in a ManyToMany relationship: use the add() method of ManyToManyCollection. Eg: blogPost.tags.add([tag]); blogPost.tags.add([tag1, tag2]);
// 10. Remove one or more related entities in a ManyToMany relationship: use the remove() method of ManyToManyCollection. Eg: blogPost.tags.remove([tag]); blogPost.tags.remove([tag1, tag2]);
// 11. Set the related entities in a ManyToMany relationship: use the set() method of ManyToManyCollection. Eg: blogPost.tags.set([tag]); blogPost.tags.set([tag1, tag2]); The set clears the existing related entities and adds the new ones.
// 12 Clear the related entities in a ManyToMany relationship: use the clear() method of ManyToManyCollection. Eg: blogPost.tags.clear();
// 13. Set the fields in the EntityManager to handle GraphQL CRUD operations. Eg: { name: 'firstName', type: BusinessEntityFieldType.VarChar, isRequiredInput: true },
// 14. A field for a Foreign Key look like this: { name: 'country', type: BusinessEntityFieldType.ForeignKey, inputName: 'countryCode', inputProperty: 'country.code', relatedManager: Country.manager },
// 15. A field for a list of Reverse Foreign Key objects (related entities) look like this: { name: 'tags', type: BusinessEntityFieldType.ReverseForeignKey, inputName: tags.id, isArrayInput: true, relatedManager: Tag.manager },
// 16. To upload files via GraphQL, create a field in the child class for the file. Eg: { name: 'logoImage', type: BusinessEntityFieldType.Upload, isInFieldDetails: false, inputProperty: 'logoImage' }. Then you can set a File object to the field. Eg: blogPost.logoImage = file;
// 
// 17. Create custom GraphQl query. Eg:
// import { gql } from '@apollo/client';
// import client from '@xFrame4/business/GraphQlClient';
// 
// async loadBadPosts(): 
// {
//     let query = `
//         query LoadBadPosts($badStatus: String!) {
//             loadBadPosts(badStatus: $badStatus) {
//                 id
//                 title
//                 content
//             }
//         }
//     `;
// 
//     let variables = { badStatus: 'BAD' };
// 
//     let { data } = await client.query({
//         query: gql(query),
//         variables: variables
//     });
// 
//     return data.loadBadPosts;
// }
// 
// 18. Use entity fields in the custom query. Eg:
// async loadBadPosts(): 
// {
//     let query = `
//         query LoadCoolPosts {
//             loadCoolPosts {
//                 ...BlogPostDetailsFragment
//             }
//         }
//     
//     ${BlogPost.manager.buildEntityDetailsFragment()}
//     `;
// 
//     let { data } = await client.query({
//         query: gql(query)
//     });
// 
//     return data.loadCoolPosts;
// }
// 
// 19. Create a custom GraphQL mutation. Eg:
// async markPostAsBad(postId: number): 
// {
//     let mutation = `
//         mutation MarkPostAsBad($postId: Int!) {
//             markPostAsBad(postId: $postId) {
//                 id
//                 title
//                 content
//             }
//         }
//     `;
// 
//     let variables = { postId: postId };
// 
//     let { data } = await client.mutate({
//         mutation: gql(mutation),
//         variables: variables
//     });
// 
//     return data.markPostAsBad;
// }
// 
// Defining the BusinessEntity
// - The BusinessEntity class represents a table in the database. It connects to the core via a GraphQL API. 
// - Architecture: TS BusinessEntity (UI) -> PHP/Django BusinessEntitySchema (API) -> PHP/Django BusinessEntity (core) -> Database table
// - For each table there is a generated BusinessEntity class that inherits from the BusinessEntity class.
// - For each generated BusinessEntity class there is a custom BusinessEntity class that inherits from the generated BusinessEntity class.
// - For each BusinessEntity class there is a BusinessEntityManager class that handles the API operations.
// - In the custom BusinessEntity class you can define additional fields and methods.
// - Here is an example of a custom BlogPost class that inherits from the generated BlogPost class:
// 
// ==========
// import { MultiSizeImage, multiSizeImageGraphQlDetails } from '@business/images/ImageGallery';
// import BlogPostGenerated, { BlogPostGeneratedEntityManager } from './generated/BlogPost.generated';
// import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
// 
// export class BlogPostEntityManager extends BlogPostGeneratedEntityManager<BlogPost>
// {
//     constructor()
//     {
//         super({
//             createEntity: () => new BlogPost(),
//             fields: [
//                 { name: 'thumbnailImageUrl', type: BusinessEntityFieldType.File, inputName: 'thumbnailImage', inputProperty: 'thumbnailImageFile' },
//                 { name: 'thumbnailImage', type: BusinessEntityFieldType.Custom, isInput: false, customGraphQl: imageInfoGraphQlDetails },
//                 { name: 'relatedPosts', type: BusinessEntityFieldType.ReverseForeignKey, inputName: 'relatedPosts.id', isArrayInput: true, relatedManager: 'self' } // use 'self' when referring to the same entity
//                 { name: 'recommendedPostAferReading', type: BusinessEntityFieldType.ForeignKey, inputName: 'recommendedPostAferReading.id', relatedManager: 'self' },
//                 { name: 'fancyTitle', type: BusinessEntityFieldType.VarChar, isInput: false },
//                 { name: 'secretKey', type: BusinessEntityFieldType.VarChar, isInFieldDetails: false }, // this is not queried but can be saved 
//             ]
//         });
//     }
// }
// 
// export class BlogPost extends BlogPostGenerated
// {
//     static _manager: BlogPostEntityManager = new BlogPostEntityManager();
//     static get manager(): BlogPostEntityManager
//     {
//         if (!this._manager) this._manager = new BlogPostEntityManager();
//         return this._manager;
//     }
// 
//     thumbnailImageUrl: string = '';
//     thumbnailImage!: ImageInfo;
//     relatedPosts: BlogPost[] = [];
//     recommendedPostAferReading: BlogPost | null = null;
//     fancyTitle!: string;
//     secretKey: string = '';
// 
//     // Override: save thumbnail image (set thumbnailImageFile property from the thumbnailImage File object)
//     async save(thumbnailImage?: File)
//     {
//         if (thumbnailImage !== undefined) this['thumbnailImageFile'] = thumbnailImage;
//         
//         return await super.save();
//     }
// }
// 
// export default BlogPost;
// ==========
// 
// - These are the members of a BusinessEntityField class:
// /** The field name. */
// name: string;
// /** The field type. */
// type: BusinessEntityFieldType;
// /** The class property associated with the input field. By default this is the field name. Can refer to a property of a property, eg: page.id or page.author.id. */
// inputProperty: string;
// /** Is the field present in the GraphQL field details query (FieldsFragment). */
// isInFieldDetails: boolean;
// /** The class property associated with the input field. By default this is the field name. */
// mapToProperty?: string;
// /** Is the field a GraphQL input field. */
// isInput: boolean;
// /** Use this if the GraphQL input field name is different from 'name'. Eg: name: user -> inputName: userId, name: imageUrl -> inputName: image */
// inputName?: string;
// /** Is this field a required input? */
// isRequiredInput: boolean;
// /** Is the input an array of entities? */
// isArrayInput?: boolean;
// /** The EntityManager object for this field (the field represents an entity). Use an EntityManager or the string 'self' if the field is a recursive field */
// relatedManager?: any;
// /** Custom GraphQL field details for subfields. */
// customGraphQl?: string;
// /** The fragment depth if it's a recursive field for the field itself. */
// recursiveFragmentDepth?: number;
// 
// - These are the available types for BusinessEntityFieldType:
// export enum BusinessEntityFieldType
// {
//     PrimaryKey = "pk",
//     /** A BusinessEntity field that has a foreign key relationship with this entity. */
//     ForeignKey = "fk",
//     /** A list for related BusinessEntity objects that have foreign key relationships with this entity. Something like 'related_name' in Django. */
//     ReverseForeignKey = "reverse_fk",
//     /** A list of unrelated BusinessEntity objects. */
//     BusinessEntityArray = "business_entity_array",
//     VarChar = "varchar",
//     Text = "text",
//     Integer = "int",
//     Decimal = "decimal",
//     Boolean = "tinyint",
//     Date = "date",
//     Time = "time",
//     DateTime = "datetime",
//     TimeStamp = "timestamp",
//     Json = "json",
//     File = "file",
//     FileList = "file_list",
//     /** A custom object that. CustomGraphQl has to be defined for this to work. */
//     Custom = "custom",
// }
// 
// - When saving files (upload) use the example from above (async save(thumbnailImage?: File)).

import { gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';
import QuoteGenerated, { QuoteGeneratedEntityManager } from './generated/Quote.generated';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';

export class QuoteEntityManager extends QuoteGeneratedEntityManager<Quote>
{
    constructor()
    {
        super({
            createEntity: () => new Quote(),
            fields: [
                { name: 'imageUrl', type: BusinessEntityFieldType.VarChar, isInput: false },
            ]
        });
    }
}

export class Quote extends QuoteGenerated
{
    static _manager: QuoteEntityManager;
    static get manager(): QuoteEntityManager
    {
        if (!this._manager) this._manager = new QuoteEntityManager();
        return this._manager;
    }

    static quoteLanguages: string[] = [
        'en',
        'de', 
        'es',
        'fr',
        'hu',
        'it',
        'pt',
    ];

    imageUrl: string = '';

    // custom mutation for generating an image for the quote
    async generateImage()
    {
        let query = `
            mutation GenerateQuoteImage($quoteId: Int!) {
                generateQuoteImage(quoteId: $quoteId) {
                    success
                    imageUrl
                }
            }
        `;

        let variables = { quoteId: this.id };

        let { data } = await client.mutate({
            mutation: gql(query),
            variables: variables
        });

        return data.generateQuoteImage.success ? data.generateQuoteImage.imageUrl : null;
    }

}

export default Quote;