import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import EntityAddEditForm from '@xFrame4/components/admin/EntityAddEditForm';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import { useLoad } from '@xFrame4/components/Hooks';
import { useTranslate } from '@xFrame4/components/Hooks';
import FacebookHelper from '@business/scheduler/FacebookHelper';
import FacebookPage from '@business/scheduler/FacebookPage';  
import FacebookPost from '@business/scheduler/FacebookPost';
import { FacebookPostTypeOfPost } from '@business/scheduler/generated/FacebookPost.generated';

interface PostToFeedFormProps
{
    facebookPage: FacebookPage;
    onClose: () => void;
}

const PostToFeedForm: FunctionComponent<PostToFeedFormProps> = (props) =>
{
    const t = useTranslate();
    const [typeOfPost, setTypeOfPost] = useState<FacebookPostTypeOfPost>(FacebookPostTypeOfPost.LINK);
    const [link, setLink] = useState<string>();
    const [linkInfo, setLinkInfo] = useState({ title: '', description: '' });
    const [photoFile, setPhotoFile] = useState<File>();
    const [message, setMessage] = useState<string>();

    /** The link has changed. */
    const onLinkChange = async (link: string) =>
    {
        let ogData = await FacebookHelper.readFacebookOgTagsFromUrl(link);
        if (ogData != null)
        {
            setLinkInfo({
                title: ogData.ogTitle!,
                description: ogData.ogDescription!
            });
        }
    };

    useEffect(() =>
    {
        if (link) onLinkChange(link);
    }, [link]);

    /** Save */
    const onPostToFacebook = async () =>
    {
        return await props.facebookPage.postToFacebook(link, photoFile, message);
    };

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="typeOfPost"
                        label="Type"
                        isRequired={true}
                        value={typeOfPost}
                        onValueChanged={(field, value) => setTypeOfPost(value['value'])}
                        dataSource={enumToDataSource(FacebookPostTypeOfPost)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="link"
                        label="Link"
                        isRequired={typeOfPost == FacebookPostTypeOfPost.LINK}
                        value={link}
                        isVisible={typeOfPost == FacebookPostTypeOfPost.LINK}
                        onValueChanged={(field, value) => setLink(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="photo"
                        label="Photo"
                        isRequired={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                        isVisible={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                        onValueChanged={(field, file) => setPhotoFile(file)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="message"
                        label="Message"
                        value={message}
                        onValueChanged={(field, value) => setMessage(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="title"
                        label="Title"
                        value={linkInfo.title}
                        isVisible={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="description"
                        label="Description"
                        value={linkInfo.description}
                        isVisible={typeOfPost == FacebookPostTypeOfPost.PHOTO}
                    />
                </Col>
            </Row>
        </>;

    /** Render */
    return (
        <>
            <EntityAddEditForm<FacebookPost>
                entity={new FacebookPost()}
                mode={AddEditFormMode.Add}
                titleAdd={props.facebookPage?.name + ': Post to feed'}
                onSave={() => onPostToFacebook()}
                onClose={() => props.onClose()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default PostToFeedForm;