import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { GridColumns } from '@mui/x-data-grid';
import ImageIcon from '@mui/icons-material/Image';
import DownloadIcon from '@mui/icons-material/Download';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import EntityView from '@xFrame4/components/admin/EntityView';
import { FieldEditorType, stringArrayToDataSource } from '@xFrame4/components/common/FieldEditor';
import { useOnAddEdit } from '@xFrame4/components/admin/EntityViewHooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import { formatDateTimeUserFriendly } from '@xFrame4/common/Functions';
import ImageCell from '@xFrame4/components/admin/ImageCell';
import Quote from '@business/quotes/Quote';
import QuoteAddEditForm from './QuoteAddEditForm';
import QuoteAddBulkForm from './QuoteAddBulkForm';

interface QuoteViewProps
{

}

const QuoteView: FunctionComponent<QuoteViewProps> = (props) =>
{
    const t = useTranslate();
    const [addEditEntity, addedEntity, editedEntity, onAddEditOpen, onAddEditClose] = useOnAddEdit<Quote>(Quote);
    const [baseFilter, setBaseFilter] = useState({});
    const [showAddBulkForm, setShowAddBulkForm] = useState(false);

    /** Grid columns. */
    const columns = React.useMemo<GridColumns<Quote>>(
        () => [
            { field: 'id', headerName: 'ID', width: 70 },
            { field: 'dateAdded', headerName: t('DATE_ADDED'), type: 'dateTime', width: 230, valueGetter: (params) => formatDateTimeUserFriendly(params.row.dateAdded) },
            { field: 'language', headerName: t('LANGUAGE'), type: 'string', width: 100 },
            { field: 'text', headerName: t('TEXT'), type: 'string', flex: 2 },
            { field: 'image', headerName: t('IMAGE'), width: 200, renderCell: (params) => <ImageCell image={{ url: params.row.imageUrl, width: 100, height: 100 }} /> },
        ],
        [],
    );

    /**
     * Generate image for quote and refresh it from DB to get the new image URL.
     */
    const generateImage = async (entity: Quote) =>
    {
        await entity.generateImage();
        setBaseFilter({ ...baseFilter }); // trigger grid refresh
    }


    /**
     * Download the generated image.
     */
    const downloadImage = (entity: Quote) =>
    {
        const link = document.createElement('a');
        link.href = entity.imageUrl;
        link.download = entity.language + '_' + entity.id + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /** Render */
    return (
        <>
            {addEditEntity &&
                <QuoteAddEditForm
                    entity={addEditEntity}
                    afterEdit={(mode, entity) => onAddEditClose(mode, entity)}
                />
            }

            {showAddBulkForm &&
                <QuoteAddBulkForm
                    onClose={() => setShowAddBulkForm(false)}
                    onAdded={() => setBaseFilter({ ...baseFilter })}
                />
            }

            <EntityView<Quote>
                id="quotes"
                title={t('QUOTES')}
                manager={Quote.manager}
                baseFilter={baseFilter}
                pageSize={15}
                buttonsLeft={[
                    {
                        text: t('ADD'),
                        variant: 'danger',
                        onClick: () => onAddEditOpen(AddEditFormMode.Add)
                    },
                    {
                        text: t('ADD_BULK'),
                        variant: 'danger',
                        onClick: () => setShowAddBulkForm(true)
                    }
                ]}
                gridInfo={{
                    columns: columns,
                    onRowDoubleClick: (entity) => onAddEditOpen(AddEditFormMode.Edit, entity)
                }}
                filterEditors={[
                    { field: 'language', label: t('LANGUAGE'), type: FieldEditorType.Select, dataSource: stringArrayToDataSource(Quote.quoteLanguages) }
                ]}
                actions={[
                    { label: t('GENERATE_IMAGE'), icon: <ImageIcon />, onClick: (entity) => generateImage(entity) },
                    { label: t('DOWNLOAD_IMAGE'), icon: <DownloadIcon />, onClick: (entity) => downloadImage(entity) }
                ]}
                deleteConfirmDialogInfo={{
                    title: (entity) => t('DELETE') + ' ' + entity.text + '?',
                    onConfirmed: (entity) => entity.delete()
                }}
                addedEntity={addedEntity}
                editedEntity={editedEntity}
            />
        </>
    );
}

export default QuoteView;